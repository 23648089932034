import React from "react";
import { Link } from "react-router-dom";
import "./News.scss";

import NewsPhotoOne from "../../../assets/images/Photos/NewsPhotoOne.png";
import NewsPhotoTwo from "../../../assets/images/Photos/NewsPhotoTwo.png";
import NewsPhotoThree from "../../../assets/images/Photos/NewsPhotoThree.png";
import NewsPhotoFour from "../../../assets/images/Photos/NewsPhotoFour.png";
import NewsPhotoFive from "../../../assets/images/Photos/NewsPhotoFive.png";
import NewsPhotoSix from "../../../assets/images/Photos/NewsPhotoSix.png";
import NewsPhotoSeven from "../../../assets/images/Photos/NewsPhotoSeven.jpg";
import NewsPhotoEight from "../../../assets/images/Photos/NewsPhotoEight.jpg";
import NewsPhotoNine from "../../../assets/images/Photos/NewsPhotoNine.JPG";
import NewsPhotoTen from "../../../assets/images/Photos/NewsPhotoTen.jpeg";
import NewsPhotoEleven from "../../../assets/images/Photos/NewsPhotoEleven.jpeg";
import NewsPhotoTwelve from "../../../assets/images/Photos/NewsPhotoTwelve.jpg";
import NewsPhotoThirteen from "../../../assets/images/Photos/NewsPhotoThirteen.png";
import NewsPhotoFourteen from "../../../assets/images/Photos/NewsPhotoFourteen.png";
import NewsPhotoFifteen from "../../../assets/images/Photos/NewsPhotoFifteen.png";
import NewsPhotoSixteen from "../../../assets/images/Photos/NewsPhotoSixteen.JPG";
import NewsPhotoSeventeen from "../../../assets/images/Photos/NewsPhotoSeventeen.jpg";
import NewsPhotoEighteen from "../../../assets/images/Photos/NewsPhotoEighteen.jpg";
import NewsPhotoNineteen from "../../../assets/images/Photos/NewsPhotoNineteen.png";
import NewsPhotoTwenty from "../../../assets/images/Photos/NewsPhotoTwenty.png";
import NewsPhotoTwentyOne from "../../../assets/images/Photos/NewsPhotoTwentyOne.JPG";
import NewsPhotoTwentyTwo from "../../../assets/images/Photos/NewsPhotoTwentyTwo.png";
import NewsPhotoTwentyThree from "../../../assets/images/Photos/NewsPhotoTwentyThree.jpg";
import NewsPhotoTwentyFour from "../../../assets/images/Photos/NewsPhotoTwentyFour.png";
import NewsPhotoTwentyFive from "../../../assets/images/Photos/NewsPhotoTwentyFive.png";
import NewsPhotoTwentySix from "../../../assets/images/Photos/NewsPhotoTwentySix.png";

const News = () => {
  return (
    <div className="news" data-aos="fade-up" data-aos-duration="3000">
      <section className="news__cards">
        <h1 className="news__cards__header">News and Events</h1>
        <div className="grid-container">
          {/* Individual card */}

          <Link
            to="/national-day-for-truth-and-reconciliation-2024"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">September 30, 2024</span>
              <h3 className="card__title">
              National Day for Truth and Reconciliation at RSG International
              </h3>
              <p className="card__text">
                On National Day for Truth and Reconciliation, RSG International
                stands in solidarity with Indigenous communities. We reflect on
                the painful legacy of residential schools and the ongoing
                journey toward healing.
                <br></br>
                <br></br>
                Today, we honour the survivors, their families, and the
                communities that supported them. It’s essential to acknowledge
                not only the harms of the past but also our commitment to
                restoring relationships with Indigenous peoples. We must
                actively support their rights, address systemic inequalities,
                and foster cultural preservation.
                <br></br>
                <br></br>
                This day, also known as Orange Shirt Day, began as a movement to
                recognize the trauma of residential schools and celebrate the
                resilience of Indigenous peoples. The orange shirt symbolizes
                the story of Phyllis Webstad, whose new shirt was taken on her
                first day at a residential school, representing the stripping
                away of identity and dignity.
                <br></br>
                <br></br>
                At RSG International, we wear orange to express our solidarity,
                raise awareness of these injustices, and remind ourselves of our
                responsibility to build a future rooted in truth, respect, and
                inclusion.
                <br></br>
                <br></br>
                As we commemorate this day, we reflect on our role in supporting
                Indigenous communities. Together, let’s work towards a more
                equitable and compassionate Canada for all.
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoTwentySix}
                alt="National Day for Truth and Reconciliation Poster."
              />
            </div>
          </Link>

          <Link
            to="/rsg-international-celebrates-national-construction-appreciation-week"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">August 20, 2024</span>
              <h3 className="card__title">
                RSG International Celebrates National Construction Appreciation
                Week
              </h3>
              <p className="card__text">
                At RSG International, we understand the tremendous effort and
                dedication that goes into every construction project. National
                Construction Appreciation Week is an opportunity to recognize
                the hard-working individuals who build and maintain our
                communities. From highways to commercial buildings, their
                contributions shape our world and drive progress forward.
                <br></br>
                <br></br>
                As we close out the week, we want to express our deepest
                gratitude to all our employees and partners who ensure that
                every job is completed with excellence. Your hard work and
                resilience are the foundation of our success at RSG
                International. We remain committed to supporting you with the
                tools, safety measures, and opportunities to thrive in our
                industry. Thank you for your dedication to making our roads
                safer!
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoTwentyFive}
                alt="Construction workers on a highway safely installing barriers."
              />
            </div>
          </Link>

          <Link
            to="/how-to-save-on-construction-costs-with-used-concrete-barriers"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">August 21, 202</span>
              <h3 className="card__title">
                How to Save on Construction Costs with Used Concrete Barriers
              </h3>
              <p className="card__text">
                Managing construction costs without compromising quality is key
                to any successful project. One effective way to achieve this is
                by incorporating{" "}
                <a href="https://strbk.com/services/used-concrete-barriers/">
                  used concrete barrier
                </a>{" "}
                into your planning. These concrete barriers offer durable and
                versatile solutions at a fraction of the cost compared to new
                materials. Whether your project involves organizing a worksite,
                managing traffic, or improving site security, used highway
                barriers can meet your needs while keeping your budget in check.
                <br></br>
                <br></br>
                <h3 className="article-page__subtitle">
                  Cost-Effective Solutions with Highway Barriers
                </h3>
                <br></br>
                When managing a construction project, controlling costs is a
                large part of the planning process. Opting for{" "}
                <a href="https://strbk.com/services/used-concrete-barriers/">
                  used concrete jersey barriers
                </a>
                can be a budget-friendly solution. Used concrete barriers offer
                the same durability and strength as new ones but at a fraction
                of the cost. If you're looking to set up boundaries or organize
                equipment, purchasing from a reliable provider like B&K
                Enterprises ensures you have access to high-quality, affordable
                barriers across Pennsylvania and Maryland with their used
                concrete barriers for sale.
                <br></br>
                <br></br>
                <h3 className="article-page__subtitle">
                  Versatility and Environmental Benefits of Used Jersey Barriers
                </h3>
                <br></br>
                In addition to costs savings, using{" "}
                <a href="https://strbk.com/services/temporary-and-permanent-barrier/">
                  pre-owned jersey barriers
                </a>{" "}
                aligns with sustainable construction practices. Reusing
                materials reduces waste, and these concrete construction
                barriers are versatile for various applications. They are
                available for immediate use while helping you achieve
                sustainability.
                <br></br>
                <br></br>
                When you’re looking for affordable and sustainable options for
                your next construction project, we would recommend looking into
                used concrete barriers.
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoTwentyFour}
                alt="Road safety barriers."
              />
            </div>
          </Link>

          <Link to="/national-traffic-awareness-month" className="card-link">
            <div className="card">
              <span className="card__date">August 20, 202</span>
              <h3 className="card__title">
                National Traffic Awareness Month at RSG International
              </h3>
              <p className="card__text">
                During National Traffic Awareness Month, we remind everyone
                about the importance of road safety and the collective
                responsibility we all share in reducing traffic-related
                incidents.
                <br></br>
                <br></br>
                With global traffic fatalities still being the leading cause of
                death, particularly among young people, raising awareness to
                this issue is critical. According to the World Health
                Organization (WHO), approximately 1.3 million people die each
                year as a result of car crashes, and between 20 to 50 million
                more suffer non-fatal injuries, often leading to long-term
                disabilities. In the United States, the National Highway Traffic
                Safety Administration (NHTSA) reported a significant rise in
                traffic fatalities, with 42,939 deaths in 2021, marking a 10.5%
                increase from the previous year, and preliminary data for 2022
                and 2023 suggests that this trend continues. These alarming
                statistics underscore the need for greater public awareness and
                proactive measures to improve road safety.
                <br></br>
                <br></br>
                As road safety specialists, we’re dedicated to raising awareness
                and doing our best to enhance road safety for all.
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoTwentyThree}
                alt="Road Safety Crew setting up a safe workplace on a highway ramp."
              />
            </div>
          </Link>

          <Link
            to="/project-highlight-bk-enterprises-installing-concrete-barrier-in-bucks-county-pennsylvania"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">July 25, 2024</span>
              <h3 className="card__title">
                B&K Enterprises Installing Concrete Barrier in Bucks County,
                Pennsylvania
              </h3>
              <p className="card__text">
                At RSG International, we value the hard work that gets put into
                each project to enhance road safety, which is why we’re proud to
                spotlight a past project from B&K Enterprises, a division of RSG
                International, and their exemplary work for James D. Morrisey,
                Inc. starting in 2019. This was a long-term project that
                involved installing and removing temporary concrete barrier and
                replacing it with permanent concrete barrier on Route 309 in
                Bucks County, Pennsylvania years later.
                <br></br>
                <br></br>
                Beginning back in 2019, B&K Enterprises started on a significant
                project for James D. Morrisey, Inc. The crews at B&K started
                with deploying temporary concrete barrier, reducing the number
                of lanes, and creating a safer work zone for the construction
                workers doing their jobs in it. Once the temporary barrier was
                in place, the work crews were able to work within the confines
                of the work zone while traffic was managed in the newly reduced
                lanes. After the work was complete, B&K’s crew returned to the
                job site to remove the temporary concrete barrier and replace it
                with permanent concrete barrier; new infrastructure that would
                keep road users safe along Route 309.
                <br></br>
                <br></br>
                Over the course of the project B&K Enterprises’ crews had to
                adhere to strict timelines, coordinate with different
                stakeholders, and ensure that the transition from temporary to
                permanent was smooth for all involved. They were able to
                seamlessly phaseout the temporary barrier and install permanent
                concrete barrier in its place, marking a significant milestone
                and embodying our entire organization’s dedication to enhancing
                road safety. Great job, B&K Enterprises!
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoTwentyTwo}
                alt="B&K Enterprises crew members installing concrete barrier in Bucks County, Pennsylvania"
              />
            </div>
          </Link>

          <Link
            to="/rsg-international-is-acquired-by-ramudden-global-bolstering-its-global-ambitions"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">June 27, 2024</span>
              <h3 className="card__title">
                RSG International is Acquired by Ramudden Global, Bolstering Its
                Global Ambitions
              </h3>
              <p className="card__text">
                In a groundbreaking transaction in the North American road
                safety infrastructure sector, RSG International, has been
                acquired by Ramudden Global, a Swedish multinational leader in
                safety infrastructure. This acquisition sets a new benchmark for
                deals in the sector, consolidating two key players in road
                safety infrastructure.
                <br></br>
                <br></br>
                “This acquisition marks a significant milestone for us,
                representing one of our largest endeavors to date. We are
                immensely excited about the prospects it offers, enabling us to
                broaden our horizons, achieve more, and elevate our performance
                to new heights,” explains Hans-Olov Blom, CEO of Ramudden
                Global.
                <br></br>
                <br></br>
                The strategic move strengthens Ramudden’s road safety offerings
                in North America, with both companies being leading providers of
                road safety infrastructure services including installation,
                product distribution, traffic management, and, notably, RSG
                International’s cutting-edge research and development division.
                <br></br>
                <br></br>
                “We’re thrilled to be part of Ramudden’s expansion into the
                North American market, as industry leaders this partnership will
                provide new opportunities to grow our market reach and better
                serve our customers while unlocking new, innovative
                opportunities,” explains Lisa Laronde, President of RSG
                International.
                <br></br>
                <br></br>
                While RSG International and its business units will remain a
                standalone division within the Ramudden Global family, this
                partnership will unlock collaboration opportunities and
                innovative possibilities, all aimed at enhancing infrastructure
                safety worldwide. Both companies are committed to cultivating
                diverse, inclusive workplaces and are aligned in their core
                values; continuing to contribute positively to their respective
                communities.
                <br></br>
                <br></br>
                Please read our FAQ below.
                <br></br>
                <br></br>
                <b className="bold-text">Frequently Asked Questions:</b>
                <br></br>
                <br></br>
                <b className="bold-text">What is Ramudden Global?</b>
                <br></br>
                <br></br>
                Ramudden Global is a global market leader in road safety
                infrastructure, the largest provider of traffic management and
                work zone safety services in the world. Based in Stockholm,
                Sweden, they have a presence across Europe and in Canada.
                <br></br>
                <br></br>
                <b className="bold-text">
                  Why would Ramudden Global be interested in RSG International?
                </b>
                <br></br>
                <br></br>
                RSG International is a proven leader in the road safety
                infrastructure sector. With both RSG International and Ramudden
                Global interested in expanding across North America, the
                organizations are well aligned in terms of long-term strategic
                goals. Furthermore, the companies share similar values including
                prioritizing safety and people.
                <br></br>
                <br></br>
                <b className="bold-text">
                  Is this acquisition part of a larger plan for RSG
                  International?
                </b>
                <br></br>
                <br></br>
                Yes! This acquisition is part of RSG International’s plan for
                expansion across North America and beyond.
                <br></br>
                <br></br>
                With Ramudden Global’s support, the goal is to accelerate RSG
                International’s growth plans for North America. In addition, RSG
                International becoming part of a larger, global organization
                will lead to further growth opportunities going forward.
                <br></br>
                <br></br>
                <b className="bold-text">
                  How will RSG International operate as part of Ramudden Global?
                </b>
                <br></br>
                <br></br>
                RSG International will retain its identity and function as a
                standalone subsidiary of Ramudden Global, operating much like it
                does.
                <br></br>
                However, an important part of the future for both organizations
                will be to build a collaborative working partnership, focused on
                sharing knowledge and best practices.
                <br></br>
                <br></br>
                <b className="bold-text">
                  How will you ensure stability for current employees?
                </b>
                <br></br>
                <br></br>
                As part of this new partnership, it was important to ensure
                organizational stability, especially in terms of job security
                for RSG International’s employees. Day-to-day roles, reporting
                structure, and business objectives will all remain the same.
                <br></br>
                <br></br>
                Stability is a cornerstone of this partnership, a priority for
                all employees, and part of the reason RSG International will
                become a standalone division of Ramudden Global.
                <br></br>
                <br></br>
                <b className="bold-text">
                  How will this affect RSG International’s culture and values?
                </b>
                <br></br>
                <br></br>
                RSG International’s culture and values will not change, as the
                company will continue operating as it always has apart from the
                change in ownership. RSG International will remain true to its
                roots but committed to evolving while collectively giving back
                to the community.
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoTwentyOne}
                alt="RSG International is acquired by Ramudden Global, bolstering its global ambitions."
              />
            </div>
          </Link>

          <Link
            to="/employee-spotlight-sharon-trac-at-powell-contracting"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">June 21, 2024</span>
              <h3 className="card__title">
                Employee Spotlight: Sharon Trac at Powell Contracting
              </h3>
              <p className="card__text">
                Meet Sharon Trac, an Accounting Administrator at Powell
                Contracting, a division of RSG International. We had the
                opportunity to sit down with Sharon as she shares her experience
                with RSG International and get a deep dive into her personal
                life. Sharon's favorite project involves reconciling projects to
                invoice for holdback, she loves doing this because it means that
                the project has been completed. She cherishes the people she
                works with, emphasizing the supportive and collaborative
                environment at RSG International. For newer employees, a big tip
                Sharon wants to share is to always, “Ask for help if you need
                it, everyone is very kind and always willing to help!"
                <br></br>
                <br></br>
                Passionate about road safety, Sharon is confident driving
                because of Powell Contracting's commitment to making sure the
                roads are safe. Outside of work, she's an adrenaline junkie who
                loves heights, traveling, and trying new things. Sharon loves to
                relax with a puzzle after a long day and has inspiring goals on
                her bucket list, such as hiking to Machu Picchu, opening her art
                exhibition, and completing a triathlon. The simple, profound
                message she has for others is, "Passion drives success. If you
                love what you do, you will always
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoTwenty}
                alt="Sharon Trac posing proudly for her photo."
              />
            </div>
          </Link>

          <Link
            to="/rsg-international-named-one-of-canadas-best-places-to-work"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">June 11, 2024</span>
              <h3 className="card__title">
                RSG International Named One of Canada’s Best Places to Work
              </h3>
              <p className="card__text">
                RSG International has been recognized as one of the best places
                to work in Canada by Human Resources Director (HRD) magazine.
                The award celebrates companies that go beyond just ensuring
                employee happiness by prioritizing their needs and providing
                them with the tools and resources for success.
                <br></br>
                <br></br>
                As a leader in the road safety infrastructure industry, RSG
                International is composed of multiple business units across
                Canada and the United States. The organization is dedicated to
                creating work environments that are both physically and
                psychologically safe, reflecting its core values and commitment
                to employee well-being.
                <br></br>
                <br></br>
                “This award would not have been possible without the tireless
                efforts of our Human Resources team, who work hard to ensure
                that our employees can share ideas, feel supported, and are
                empowered and valued,” said Paul Stewart, Director of Human
                Resources at RSG International. “As we celebrate this
                significant achievement, we are committed to building on this
                momentum and driving positive change within our organization as
                we continue to nurture our culture of collaboration, empathy,
                and growth.”
                <br></br>
                <br></br>
                Now in its third year, the award is based on employee feedback
                and requires a satisfaction rating of at least 75 percent to
                qualify. This recognition highlights RSG International’s success
                in creating a workplace where employees feel engaged, satisfied,
                and valued.
                <br></br>
                <br></br>
                “This award reflects our collective commitment to fostering an
                environment where every voice is heard, every contribution is
                valued, and every team member thrives,” added Lisa Laronde,
                President of RSG International. “Congratulations to our teams in
                Canada and the United States on this outstanding achievement.”
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoNineteen}
                alt="Three women working happily."
              />
            </div>
          </Link>

          <Link
            to="/national-indigenous-history-month-celebrating-indigenous-cultures"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">June 5, 2024</span>
              <h3 className="card__title">
                National Indigenous History Month: Celebrating Indigenous
                Cultures
              </h3>
              <p className="card__text">
                National Indigenous History Month gives us the chance to learn
                about the unique cultures and traditions of First Nations,
                Métis, and Inuit communities. By understanding their history, we
                can work towards reconciliation and a more inclusive future.
                With Indigenous voices continuing to inspire positive change.
                <br></br>
                <br></br>
                Properly educating ourselves and providing safe spaces for open
                discussion is the essence of what National Indigenous History
                Month is all about. This month, and every month, RSG
                International stands with Indigenous communities across Canada
                and recognizes the important role they have played in building
                the Canada we know today. Whether we’re talking about past or
                present injustices, we will continue to raise awareness of the
                issues these communities face.
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoEighteen}
                alt="National Indigenous History Month poster."
              />
            </div>
          </Link>

          <Link
            to="/celebrating-pride-month-at-rsg-international"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">June 3, 2024</span>
              <h3 className="card__title">
                Celebrating Pride Month at RSG International
              </h3>
              <p className="card__text">
                At RSG International, we are proud to celebrate Pride Month this
                June, honouring the 2SLGBTQIA+ community and their ongoing fight
                for equality and inclusion. This month, along with celebrating
                and showing support, we reflect on the importance of creating
                welcoming and accepting spaces in the workplace and beyond.
                <br></br>
                <br></br>
                We believe in standing up for one another, treating everyone
                with equality and respect, and allowing for all of our employees
                to be surrounded in an environment where they feel supported and
                valued. Creating an inclusive environment, where everyone feels
                valued and respected, has been and will continue to be a
                priority for us. Furthermore, we believe it’s an important time
                to look back at the history of the Pride movement and how far
                we’ve come as a society in this fight for civil rights.
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoSeventeen}
                alt="A Pride Flag proudly waving in the blue sky."
              />
            </div>
          </Link>

          <Link
            to="/rsg-international-lights-the-path-for-pink-titans-robotics-in-2025"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">May 30, 2024</span>
              <h3 className="card__title">
                RSG International Lights the Path for Pink Titans Robotics in
                2025!
              </h3>
              <p className="card__text">
                RSG International is thrilled to announce our sponsorship of the
                Pink Titans Robotics team from David & Mary Thomson Collegiate
                Institute in Toronto, Ontario for the 2025 season. This
                partnership highlights our dedication to supporting STEM
                education and empowering the next generation of innovators.RSG
                International is thrilled to announce our sponsorship of the
                Pink Titans Robotics team from David & Mary Thomson Collegiate
                Institute in Toronto, Ontario for the 2025 season. This
                partnership highlights our dedication to supporting STEM
                education and empowering the next generation of innovators.
                <br></br>
                <br></br>
                “This partnership will help us reach more students, especially
                young women, through workshops focused on robotics, gender
                equity in STEM, and leadership opportunities,” explained Elliot
                Steele, Vice President, Technology at RSG International. “We
                also look forward to offering mentorship opportunities where our
                industry professionals can share their knowledge and inspire
                students to consider a career in the road safety infrastructure
                sector.”
                <br></br>
                <br></br>
                RSG International is at the forefront of road safety
                infrastructure worldwide, with a diverse portfolio of business
                units specializing in construction and installation, material
                distribution, and new product development.
                <br></br>
                <br></br>
                The Pink Titans robotics team consists of high school students
                from grades 9 to 12 who have shown remarkable talent and
                commitment in the FIRST Robotics Competition (FRC), a renowned
                global STEM program. Over a challenging eight-week period, the
                team collaborates to design, build, and program a 50-kg robot to
                tackle intricate tasks. In the previous season, their robot,
                crafted to pick up foam rings and score them in designated
                areas, propelled them to their most successful year yet. The
                team clinched two Innovation in Control Awards and the
                prestigious Impact Award at three competitions, including the
                provincial championship. Furthermore, one of their mentors
                received recognition with the Volunteer of the Year Award in the
                FIRST LEGO League Explore program.
                <br></br>
                <br></br>
                “With financial support, the team will be able to participate in
                the 2025 season.  The finances are limited in a community with
                many external challenges affecting student success. The Pink
                Titans are very grateful to RSG International for helping ensure
                the team's continued sustainability,” added Edmund Kim, coach of
                the Pink Titans and a biology teacher. “Robotics is challenging,
                but the experience is very rewarding when the students work
                alongside success and see their future in the industry.”
                <br></br>
                <br></br>
                RSG International is deeply committed to fostering talent and
                opening doors for young minds to excel in STEM fields and is
                eager to join forces with The Pink Titans to create impactful
                experiences that will not only enhance student’s technical
                skills but also prepare them for success in higher education and
                the workforce.
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoSixteen}
                alt="RSG International's Pink Titans Robotics Team posing for a photo with their award."
              />
            </div>
          </Link>

          <Link
            to="/rsg-international-hr-team-clinches-the-innovative-hr-teams-award"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">May 14, 2024</span>
              <h3 className="card__title">
                RSG International's HR Team Clinches the Innovative HR Teams
                Award
              </h3>
              <p className="card__text">
                RSG International congratulates the People and Culture team at
                180 Ram Forest Road for their recognition as one of Canada's
                ‘Most Innovative HR Teams for 2024’.
                <br></br>
                <br></br>
                Each year, the HR Reporter acknowledges organizations that push
                the boundaries in the HR sector. These organizations introduce
                new technology, highlight individual achievements, implement
                innovative recognition strategies, and adopt progressive
                recruitment approaches.
                <br></br>
                <br></br>
                “Being chosen as a winner for Innovative HR teams is truly an
                incredible achievement! It means a lot to me because it reflects
                the dedication and uniqueness of our HR department. We’re
                committed to introducing fresh new ideas and initiatives, while
                continuously seeking to improve our practices. We all are
                genuinely committed to enhancing the employee experience, and
                I'm honoured to be a part of this exceptional team,” explains
                Leah Marche, People and Culture Specialist for Powell
                Contracting, a division of RSG International.
                <br></br>
                <br></br>
                Our People and Culture team embraces an open-door policy,
                organizing lively employee events to foster departmental
                mingling during work hours, thereby enhancing accessibility.
                Additionally, the team prioritizes destigmatizing mental health
                issues and cultivating an inclusive environment through training
                and peer-to-peer learning.
                <br></br>
                <br></br>
                “I am delighted to have won the Canada's Most Innovative HR
                Teams Award. It is a testament to the dedication, creativity,
                and tireless efforts of our HR team in revolutionizing our
                workplace practices,” adds Paul Stuart, Director of Human
                Resources at RSG International. “This recognition not only
                validates our hard work but also inspires us to push boundaries
                further, setting new standards for HR excellence. Together,
                we've sculpted a culture where innovation thrives, propelling
                our company toward greater success and fostering a truly
                transformative workplace.”
                <br></br>
                <br></br>
                Congratulations team!
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoFifteen}
                alt="RSG International's HR Team posing for a photo with their award."
              />
            </div>
          </Link>

          <Link
            to="/chantal-seguin-elected-president-of-the-simcoe-county-heavy-construction-association"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">May 08, 2024</span>
              <h3 className="card__title">
                Chantal Séguin Elected President of the Simcoe County Heavy
                Construction Association
              </h3>
              <p className="card__text">
                The Simcoe County Heavy Construction Association (SCHCA) has
                named Chantal Séguin its new and first woman president, marking
                a significant milestone in the organization’s history and the
                construction industry.
                <br></br>
                <br></br>
                Séguin serves as the General Manager of Summit Rentals, a
                prominent supplier of road safety devices specializing in
                temporary concrete barriers, noise barriers, and crash cushions.
                Summit Rentals is a division of RSG International, a global
                leader in the road safety infrastructure sector.
                <br></br>
                <br></br>
                “I am honoured to have been appointed as President, the members
                have advocated for me and championed my causes since joining the
                board in 2019,” explained Séguin. “I love that we advocate for
                members within Simcoe County, a place I happily call home.”
                <br></br>
                <br></br>
                Bringing over a decade of experience in heavy construction,
                Séguin has held management positions for general contracting
                road construction, rail, and road safety devices. She is also a
                member of the Board of Directors for the Barrie Construction
                Association (BCA).
                <br></br>
                <br></br>
                “RSG International is thrilled for Chantal (Séguin) as she takes
                on this new role. She is laying the foundation for a future
                where equality and opportunity know no bounds. A tireless
                advocate, we’re excited to witness her transformative impact on
                the industry,” added Lisa Laronde, the first woman President of
                RSG International.
                <br></br>
                <br></br>
                Séguin says during her two-year term she intends to keep
                advocating for youth through various grassroots programs offered
                by the association while exposing guidance counselors to the
                trades.
                <br></br>
                <br></br>
                “They’re the driving force behind the industry and we need them
                to carry our message, that the trades lack profitability because
                it is quite the opposite, these are skilled jobs and we need new
                workers to sustain the industry,” remarked Séguin.
                <br></br>
                <br></br>
                Séguin was recently announced as the new president at the
                Engineer’s Night.
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoFourteen}
                alt="Chantal Séguin posing for a photo with arms crossed in front of a white background."
              />
            </div>
          </Link>

          <Link
            to="/lisa-laronde-president-of-rsg-international-has-been-named-one-of-the-most-influential-people-of-2024-by-sitenews"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">May 07, 2024</span>
              <h3 className="card__title">
                Recognizing Mental Health Awareness Month
              </h3>
              <p className="card__text">
                At RSG International, we are fully committed to supporting
                Mental Health Awareness Month this May. We need to overcome the
                stigma that frequently surrounds mental health concerns and
                commit to supporting one another. The construction industry in
                particular has made strides towards improving employee wellness,
                through new initiatives, employee programs, and more, but has a
                long way to go.
                <br></br>
                <br></br>
                This month, our employees will participate in multiple workshops
                and other activities that deepen our understanding of mental
                health. We're working to create a work environment where
                everyone feels empowered to speak up and encouraged to succeed.
                We have set up strong resources and support networks to ensure
                that every member of our team can get the support they need.
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoThirteen}
                alt="Workers on a road construction site."
              />
            </div>
          </Link>

          <Link
            to="/lisa-laronde-president-of-rsg-international-has-been-named-one-of-the-most-influential-people-of-2024-by-sitenews"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">April 23, 2024</span>
              <h3 className="card__title">
                Lisa Laronde Named to Most Influential People in Construction
                2024
              </h3>
              <p className="card__text">
                We are excited to share that Lisa Laronde, President of RSG
                International, has been named one of the Most Influential People
                of 2024 by SiteNews. This recognition is awarded to leaders who
                significantly impact the construction industry through
                innovation, strong leadership, and dedication.
                <br></br>
                <br></br>
                Lisa's accomplishment demonstrates how she has advanced the
                industry. Her devotion to excellence has not only helped RSG
                International grow but has also raised the bar for the
                construction industry. Her influence makes her a passionate
                advocate for workforce development, believing strongly in
                empowering employees through continuous education and
                professional growth opportunities.
                <br></br>
                <br></br>
                Receiving this award from Site News Construction celebrates
                Lisa's personal contributions and reinforces RSG International's
                role as a leader in the construction sector.
                <br></br>
                <br></br>
                Congratulations to Lisa on this well-deserved recognition. Her
                vision and dedication continue to inspire all of us at RSG
                International in everything we do.
                <br></br>
                <br></br>
                Please read our press release{" "}
                <a
                  href="https://www.einpresswire.com/article/705902855/rsg-international-s-president-lisa-laronde-recognized-among-construction-s-most-influential-people"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="article-page__link"
                >
                  here
                </a>
                .<br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoTwelve}
                alt="Lisa Laronde standing with the SiteNews award for Most Influential People of 2024."
              />
            </div>
          </Link>

          <Link
            to="/canadas-first-teledriven-crash-truck-debuted-at-intertraffic"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">Apr 17, 2024</span>
              <h3 className="card__title">
                Canada's First Teledriven Crash Truck Debuted at Intertraffic
              </h3>
              <p className="card__text">
                The debut of Canada’s first tele-driven crash truck is set to
                take place at Intertraffic Amsterdam, the world’s premier
                international trade fair dedicated to traffic infrastructure,
                management, road safety, and parking.
                <br></br>
                <br></br>
                RSG International, a global leader in road safety
                infrastructure, recently inked a licensing agreement with Elmo,
                the Estonian company behind groundbreaking road legal
                tele-driving technology. Significant progress has been made
                after an early Christmas gift in the form of a Ford F550 that
                arrived last year in Estonia from Canada. Since then, the truck
                has been retrofitted and will soon be on display in the North
                American market.
                <br></br>
                <br></br>
                "This project hasn't been without its hurdles. While we've had
                prior experience and were proficient in upgrading electrical
                vehicles in past projects, the Ford F550 posed unique challenges
                with its hydraulic power steering and braking system. However,
                after overcoming some trial and error, our team of great
                engineers successfully adapted our technology. Now, we possess
                the necessary skills and experience to retrofit an entire fleet
                of crash trucks,” adds Enn Laansoo, Jr., CEO and Founder of
                Elmo.
                <br></br>
                <br></br>
                The technology will have a live presentation at Intertraffic
                demo area D1. The vehicle, which is still in Tallinn, will be
                driven remotely from Amsterdam by a licensed tele-driver under
                the Estonian Traffic Department.
                <br></br>
                <br></br>
                The truck can be taken throughout the European Union for
                promotions until May when it will be delivered back to Canada.
                <br></br>
                <br></br>
                “The integration of this technology would in theory allow us to
                remove drivers from crash truck vehicles, ensuring their safety
                during operations. Our research and development team are eager
                to do our testing to see if tele-driving will be a good fit for
                the road and construction industry across Canada,” explains
                Elliot Steele, Vice President, Technology at RSG International.
                <br></br>
                <br></br>
                The live presentation of the trek-driven Ford F550 crash truck
                in the Netherlands is organized together with Dutch Automated
                Mobility (DAM), a pioneer in autonomous vehicle technology. The
                partnership agreement with DAM also includes teledriving
                technology promotion and licensing in Netherland market.
                <br></br>
                <br></br>
                Elmo’s road legal tele-driving technology has been used in
                public car-sharing services since 2022 and is legal today in 3
                countries. The company, which is developing similar technology
                for military use, has run testing and started permitting in
                Germany, France, Switzerland, Austria, the Netherlands, the US,
                and other countries.
                <br></br>
                <br></br>
                Please read our press release{" "}
                <a
                  href="https://tech.einnews.com/amp/pr_news/703829242/tele-driving-breakthrough-canadian-crash-truck-makes-global-debut-in-amsterdam"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="article-page__link"
                >
                  here
                </a>
                .<br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoEleven}
                alt="Attendees of a conference testing out the new teledriven truck system."
              />
            </div>
          </Link>

          <Link
            to="/president-of-rsg-international-launches-podcast-on-international-womens-day"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">Mar 08, 2024</span>
              <h3 className="card__title">
                Lisa Laronde Launches Podcast on International Women’s Day
              </h3>
              <p className="card__text">
                Lisa Laronde, recognized as one of Canada’s ‘Top 100 Most
                Powerful Women,’ is a dynamic advocate for women in construction
                and leadership roles. Being the first woman president of RSG
                International, Lisa has not only shattered stereotypes but also
                led the company to experience a significant surge in profit
                under her leadership, setting a precedent for others in the
                male-dominated industry. Now, the highly sought-after keynote
                speaker is embarking on an exciting journey as she launches a
                podcast on a new and exciting women’s network aimed at
                empowering women to lead with confidence and drive positive
                change.
                <br></br>
                <br></br>
                “The Powerhouse Project” recently launched on the{" "}
                <a
                  href="https://www.womeninmedia.network/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="article-page__link"
                >
                  Women In Media Network
                </a>
                , (WIMN) a new home for women and gender-diverse podcasters on a
                mission to uplift, inspire, and empower one another.
                <br></br>
                <br></br>
                “Joining the Women in Media network is an opportunity to reach
                and inspire women from around the globe. It is a place to
                celebrate our collective success and provides an opportunity to
                come together, lift one another, and foster success” adds Lisa
                Laronde show host and the first woman president of RSG
                International, a global leader in road safety infrastructure.
                <br></br>
                <br></br>
                Created by Canadian media personality, producer, and host of the
                popular Women in Media podcast, Sarah Burke. Burke’s vision for
                WIMN is more than just an advocacy group; it's a movement.""
                <br></br>
                <br></br>
                It's about transforming the media landscape into a diverse and
                equitable arena where women's voices are not just heard but are
                influential,” explains Sarah Burke. “The media landscape is in a
                perpetual state of turbulence right now, and I’m excited about
                empowering creators with newfound freedom from the organizations
                they were once tied to, and the power to make decisions
                regarding their content.”
                <br></br>
                <br></br>
                Laronde joins a multi-platinum, award-winning singer,
                songwriter, actor, author, and podcaster Jann Arden, in addition
                to TV host, home designer, and author Debbie Travis. The network
                highlights a wide range of other women creators ranging from
                relationships and health to finance and women's issues in
                addition to careers and real estate.
                <br></br>
                <br></br>
                “I know what it takes to succeed in this exciting and ferocious
                world. The platform Sarah (Burke) has created shines a light on
                women who inspire, women who are struggling, women who are role
                models, and women who are unafraid to speak their minds,”
                explains Debbie Travis, the British-Canadian television
                personality.
                <br></br>
                <br></br>
                ‘The Powerhouse Project’ is a bi-weekly podcast sponsored by RSG
                International to recognize women in construction and leadership
                roles. It covers leadership from a variety of angles inviting
                distinguished guests to speak about their experiences in tech,
                politics, construction, and more. Lisa operates with a no-filter
                policy and no-nonsense approach that surprises some but inspires
                many.
                <br></br>
                <br></br>
                “We hope listeners connect with Lisa’s electric personality, she
                is the type of person who ignites a spark from within, infusing
                those around her with a sense of drive and ambition. The podcast
                is thought- provoking and aims to inspire our listeners to
                embrace their potential while exploring the failures and success
                stories of others,” adds Morganne Campbell the Public Relations
                Manager for RSG International.
                <br></br>
                <br></br>
                Lisa is also the president of the Canadian Association of Women
                in Construction (CAWIC). She also holds a seat on the largely
                male-dominated board of directors for the Ontario Road Builders’
                Association (ORBA.)
                <br></br>
                <br></br>
                New episodes are published every other Wednesday, you can join
                the movement at{" "}
                <a
                  href="https://www.thepowerhouse-project.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="article-page__link"
                >
                  www.thepowerhouse-project.com
                </a>
                . Follow us on Facebook and Instagram at ‘The Powerhouse Project
                Podcast.’
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoTen}
                alt="Lisa Laronde standing in fron of an image with the podcast title."
              />
            </div>
          </Link>

          <Link
            to="/celebrating-women-in-construction-week"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">Mar 04, 2024</span>
              <h3 className="card__title">
                Celebrating Women in Construction Week
              </h3>
              <p className="card__text">
                RSG International is proud to celebrate Women in Construction
                Week, an annual event dedicated to highlighting the importance
                of women in the construction industry and advocating for their
                increased presence and leadership in the field. This week-long
                celebration aligns with our commitment to diversity, equity, and
                inclusion within the construction sector and beyond.
                <br></br>
                <br></br>
                "Women in Construction Week is not just about celebrating
                achievements; it's about creating conversations that lead to
                real change in the industry. It's about building a future where
                women have equal opportunities to contribute, lead, and
                innovate," says Lisa Laronde, President of RSG International.
                Lisa, a vocal advocate for gender equality in construction,
                emphasizes the importance of this week as a stepping stone
                towards greater inclusion and diversity in the industry.
                <br></br>
                <br></br>
                Women in Construction Week is an opportunity for us all to
                reflect on the progress made and the journey ahead. RSG
                International remains committed to breaking down barriers and
                creating an inclusive environment where everyone, regardless of
                gender, can thrive.
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoNine}
                alt="A large group of construction workers posing in front of trucks and equipment."
              />
            </div>
          </Link>

          <Link
            to="/jason-spencer-joins-rsg-internationals-executive-team"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">Feb 22, 2024</span>
              <h3 className="card__title">
                Jason Spencer Joins RSG International’s Executive Team
              </h3>
              <p className="card__text">
                We’re very pleased to welcome Jason Spencer to our executive
                team. A seasoned leader, Spencer will be responsible for
                developing and implementing strategic initiatives to drive
                growth, enhance competitiveness, and optimize overall
                performance across all its business units.
                <br></br>
                <br></br>
                “Jason brings diverse perspectives and expertise to the table,
                and my executive and senior leadership teams are looking forward
                to collaborating with him, as he helps propel the organization
                forward and we continue to lead and innovate in the road safety
                industry,” explains Lisa Laronde, President of RSG
                International.
                <br></br>
                <br></br>
                Spencer is currently the President of EKHO Infrastructure
                Solutions and Durisol North America, which specialize in precast
                concrete infrastructure products, including sound and retaining
                wall products. Spencer has a proven track record of achievement
                in the construction materials supply sector, including expanding
                operations in Canada and the U.S. In addition, he is a seasoned
                leader who excels in negotiation, budgeting, management, and
                business development.
                <br></br>
                <br></br>
                “I’m looking forward to this journey with a new leadership team.
                I am eager to contribute, collaborate, and drive success
                together,” adds Jason Spencer.
                <br></br>
                <br></br>
                Spencer steps into his new role as a Director at RSG
                International on March 4th, 2024.
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoEight}
                alt="Jason Spencer standing professionally."
              />
            </div>
          </Link>

          <Link
            to="/lisa-laronde-is-appointed-to-the-board-of-orba"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">Feb 9, 2024</span>
              <h3 className="card__title">
                Lisa Laronde Is Appointed to the Board of ORBA
              </h3>
              <p className="card__text">
                Lisa Laronde, the first woman President of RSG International, a
                global leader in road safety infrastructure, has been named to
                the board of directors of the Ontario Road Builders’ Association
                (ORBA).
                <br></br>
                <br></br>
                “I believe this is an indication the industry is changing.
                Although I might be the only woman on the board today, we must
                start somewhere. I have a voice and I’m going to use it,”
                explains Lisa Laronde. “I think this is the beginning of
                something extraordinary.”
                <br></br>
                <br></br>
                The announcement was made during ORBA’s 97th Annual Convention
                and Annual General Meeting (AGM) held at the Fairmont Royal York
                in Toronto. This year's theme ‘Building Momentum: Engaging the
                Next Generation,’ underscores the organization's unwavering
                commitment to driving industry progress and aligning with
                Ontario’s growing and evolving infrastructure needs.
                <br></br>
                <br></br>
                “We are excited to welcome Lisa Laronde to ORBA’s board of
                directors where she joins leaders of Ontario’s transportation
                infrastructure industry,” said Walid Abou-Hamde the CEO of ORBA.
                “Lisa’s versatile skillset and experience will support the
                Association in implementing its strategic plan and advancing its
                members’ interests.”
                <br></br>
                <br></br>
                Laronde was recently recognized as one of Canada’s ‘Top 100 Most
                Powerful Women’, she’s also the President of the Canadian
                Association of Women in Construction (CAWIC) and is widely known
                as a fierce advocate for women in leadership. Laronde is
                passionate about building psychologically safe work environments
                where diversity and inclusion are at the forefront of any
                organization she’s a part of.
                <br></br>
                <br></br>
                “Lisa (Laronde) is a trailblazer who breaks barriers and paves
                the way for others to follow, her appointment to the Ontario
                Road Builders’ Association is a testament to her exceptional
                leadership and a beacon for the future of women in the
                industry,” explains Jamie West a Board Member at CAWIC and
                President of Peninsula Construction, a division of RSG
                International.
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoSeven}
                alt="Lisa Laronde standing proudly in front of a yellow wall."
              />
            </div>
          </Link>

          {/* Individual card */}
          <Link
            to="/mark-ayton-from-safe-roads-research-development-becomes-the-first-canadian-to-win-a-lifetime-award-from-the-transportation-research-board"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">Jan 22, 2024</span>
              <h3 className="card__title">
                Mark Ayton Receives TRB Lifetime Award
              </h3>
              <p className="card__text">
                “Find a job you enjoy doing, and you will never have to work a
                day in your life,” those words spoken by Mark Twain ring true
                for Mark Ayton, a civil engineer at Safe Roads Research &
                Development, who has dedicated four decades to designing and
                building safer roads.
                <br></br>
                <br></br>
                “The day that it starts to become a drag or a chore, that’s the
                time to step away or change the channel, if you will,” explains
                Mark Ayton.
                <br></br>
                <br></br>
                Ayton was recently recognized for his dedication to the industry
                by the Transportation Research Board (TRB) and was awarded the
                Kenneth A. Stonex Roadside Safety Award, which recognizes
                lifetime contributions to roadside safety. Established in 1991,
                the award was originally sponsored by General Motors, and named
                after an employee who was a pioneer for roadside safety long
                before the seriousness of ran off road crashes was recognized by
                transportation agencies.
                <br></br>
                <br></br>
                “It was a shock when they called me to say that I was receiving
                the award, look at the list of people who have won that award.
                There are some heavy hitters on that list,” adds Ayton. “To be
                considered in the same breath as them is pretty overwhelming.”
                <br></br>
                <br></br>
                Looking back at his career, Ayton has a long list of
                accomplishments, and rightfully so. He spent more than 30 years
                with the Ministry of Transportation, with the first 12 years at
                the MTO’s London office where he worked in design and
                construction. Ayton moved to the St. Catharines office where he
                spent his last 19 years working in engineering standards where
                became responsible for the MTO’s Roadside Design Manual,
                Geometric Design Manual, and all the related design policies,
                guidelines, and construction standards that went along with it.
                <br></br>
                <br></br>
                “Now that I look back over the years, we probably have saved a
                lot of lives. I guess it's something we just never take account
                of especially median barrier projects,” adds Ayton.
                <br></br>
                <br></br>
                His most noteworthy accomplishments include the design of
                highway 401 concrete median barrier and widening between London
                and Woodstock in the 1990’s, which was a large undertaking for a
                young engineer at the time, Ayton still feels a sense of pride
                when he travels that stretch of highway.
                <br></br>
                <br></br>
                Another notable project was the 120-kilometer stretch of the 401
                just west of London, dubbed ‘carnage alley’ which made headlines
                when safety advocates began lobbying the provincial government
                for the widening of the highway from 4 lanes to 6 including
                concrete median barriers. That project would take years to get
                off the ground and Ayton knew that, and instead introduced a new
                plan, installing a high tension four cable median barrier
                system, making the highway safer within a shorter timeframe.
                <br></br>
                <br></br>
                “I found an article from three years ago that pointed at the
                annual cost of repairs to the cable barrier system, it
                highlighted repairs from 68 different impacts. What the article
                failed to mention was how each of those impacts potentially
                prevented a vehicle from crossing the median into opposing
                traffic, leading to a potential fatality or serious injury.
                These are the success stories we need to get out there.”
                <br></br>
                <br></br>
                Now Ayton spends his days with his team at Safe Roads Research &
                Development, a division of RSG International. He is a technical
                expert in geometric and road safety design, developing, and
                testing cutting-edge road safety products.
                <br></br>
                <br></br>
                “I met Mark around 2000 and his passion for roadside safety was
                immediately evident. Ontario and all of Canada have benefitted
                from his passion. Fatalities from roadside accidents have
                continuously diminished under his watch at MTO. Working with him
                since 2018 has been a capstone to my career,” adds Dean
                Alberson, a Senior Research Engineer at Safe Roads Research &
                Development.
                <br></br>
                <br></br>
                Ayton jokes that since joining the team five years ago his job
                doesn’t seem like work as he develops and crash tests products
                including several that are patented including a combination MASH
                TL-3 and TL-4 traffic barriers with soundwall. He says he enjoys
                partnering with outside organizations including the Ontario
                Provincial Police.
                <br></br>
                <br></br>
                “The R&D team is a team full of innovators and we're always
                looking at trying to come up with new products that'll do things
                better, more cost-effectively, and be easier to place out in the
                road,” explains Ayton. “You can probably put our team up against
                anybody, I don’t think there’s a better team out there, we’re
                disrupters, we’re looking at changing how things are done.”
                <br></br>
                <br></br>
                These words were echoed by Lisa Laronde, president of RSG
                International.
                <br></br>
                <br></br>
                “Our success as an industry hinges on people like Mark Ayton, a
                brilliant mind whose goal is to create safe roads for motorists
                and prevent fatalities. We're proud of Mark’s accomplishments
                and are confident in his ability to rise to the occasion,” adds
                Laronde.
                <br></br>
                <br></br>
                But 16 months ago, life as Ayton knew it changed, he was getting
                ready to compete in a half-ironman distance triathlon when his
                wife Joanne noticed his skin was turning yellow. After some
                convincing Ayton found himself in a local emergency department
                and following a series of medical tests over the course of a
                week and was diagnosed with stage four pancreatic cancer.
                <br></br>
                <br></br>
                “Work keeps me going, Lisa, Ben, Bill, they’ve all been so
                accommodating,” explains Ayton who also says another motivator
                is participating in endurance sports.
                <br></br>
                <br></br>
                In fact, in September of 2023 Ayton has his shot a do over and
                participated in the triathlon he missed the year before. Ayton
                is only person with stage four cancer, also on chemotherapy, to
                ever complete the Barrelman Triathlon in Niagara Falls.
                <br></br>
                <br></br>
                “I defied expectations by not only surviving year one of my
                cancer journey, but I completed a grueling and demanding
                endurance race placing 469th out of 600.”
                <br></br>
                <br></br>
                And the father of three says he plans to continue to defy the
                odds, he intends to be part of the 12% of people who survive
                pancreatic cancer for more than five years. This means he will
                continue his work with Safe Roads, admittedly it doesn’t feel
                like work as it is a job he loves to do, he has this piece of
                advice for up-and-comers.
                <br></br>
                <br></br>
                “Work hard and look around, you never know what you can learn on
                a site just by being interested and open to learning new skills.
                It can open doors and introduce new opportunities you never knew
                existed.”
                <br></br>
                <br></br>
                Ayton joins a long list of award recipients which can be found{" "}
                <a
                  href="https://sites.google.com/site/trbcommitteeakd20/awards?authuser=0"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="article-page__link"
                >
                  here
                </a>
                <br></br>
                <br></br>
                Please read our press release{" "}
                <a
                  href="https://www.einnews.com/pr_news/681919794/canadian-civil-engineer-recognized-for-contributions-to-roadside-safety-at-us-based-awards-ceremony"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="article-page__link"
                >
                  here
                </a>
                <br></br>
                <br></br>
              </p>
              <img
                className="card__image"
                src={NewsPhotoSix}
                alt="Two men accepting an award."
              />
            </div>
          </Link>

          <Link
            to="/rsg-international-looks-to-team-up-employees-with-ai-partner"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">Nov 27, 2023</span>
              <h3 className="card__title">
                RSG International Looks To Team Up Employees With AI Partner
              </h3>
              <p className="card__text">
                This new partnership with the Estonian technology company could
                change the road building industry and the way we do business at
                RSG International.
                <br></br>
                <br></br>
                “We’d like to show people the construction industry is creative
                and innovative,” explains Elliot Steele, the VP of technology
                for RSG International.
                <br></br>
                <br></br>
                Working alongside software engineering company RedBit
                Development and QMind, a group of undergraduate students from
                Queen’s University, RSG International is working to develop the
                AI Partner, a tool that can be used to assist employees in
                understanding, creating, and elevating proposals for contracts
                within the industry, leading to more efficient and concise bids
                which could lead to more awarded contracts.
                <br></br>
                <br></br>
                “We want to pair our staff members across our brands with a tool
                they can use to accelerate the understanding of complex
                documents,” adds Steele.
                <br></br>
                <br></br>
                As RSG International continues to grow and diversify its
                workforce, if successful, the AI Partner is expected to give
                employees a better understanding of complex documentation and
                help aid in training programs during the onboarding process in
                addition to policies, standards, and specifications outlined by
                the Ministry of Transportation (MTO.)
                <br></br>
                <br></br>
                The goal of the project is not to replace humans, the AI Partner
                is intended to superpower employees in their day-to-day tasks at
                work.
                <br></br>
                <br></br>
                “We hope to gain knowledge of how AI can affect our businesses
                and how we can leverage it to move faster and adapt to the
                ever-changing environment. In addition, we’re looking to partner
                with Canadian firms to ensure this technology remains Canadian,”
                added Steele.
                <br></br>
                <br></br>
                RedBit Development and undergraduate students from Queens
                University are developing the software and hope to showcase it
                during the 2024 Canadian Undergraduate Conference on Artificial
                Intelligence (CUCAI.) .<br></br>
                <br></br>
                “Working with RedBit has broadened my horizons as a project
                manager and given me valuable experience working with
                client-facing projects. I believe in the near future, AI will be
                integrated seamlessly into the workplace and magnify the
                productivity of employees in all industries and fields,”
                explains Brandon Cheung, the team lead with QMind.
                <br></br>
                <br></br>
                RSG International and RedBit Development believe in mentorship
                and say the team at QMind has been an integral part of the
                proof-of-concept process.
                <br></br>
                <br></br>
                The annual conference is being held in Kingston, Ont., March
                2-3.
              </p>
              <img
                className="card__image"
                src={NewsPhotoFive}
                alt="A truck and a worker on a drirt sideroad."
              />
            </div>
          </Link>

          <Link
            to="/lisa-laronde-wxn-2023-canadas-most-powerful-women"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">Oct 31, 2023</span>
              <h3 className="card__title">
                Lisa Laronde: WXN 2023 Canada's Most Powerful Women
              </h3>
              <p className="card__text">
                She’s known as an unapologetic, purpose-driven leader who
                elevates those around her, creates opportunities for people of
                diverse backgrounds, and cultivates a company culture of respect
                and appreciation.
                <br></br>
                <br></br>
                Lisa Laronde, president of RSG International, is being
                recognized as one of Canada’s Most Powerful Women through the
                Women’s Executive Network (WXN) Top 100 list for 2023.
                <br></br>
                <br></br>
                “I truly never expected to be in this position, and it is an
                absolute honour to be recognized, as this award validates all
                that I have done and all I have achieved,” explains Laronde.
                <br></br>
                <br></br>
                Laronde has transformed RSG International into a worldwide
                leader in the road safety sector, marking a surge in revenue and
                profit since taking over as president in 2020. Moreover, Laronde
                is dedicated to empowering women in construction, a sector which
                is still very much male-dominated.<br></br>
                “I love what I do and the construction industry, but I cannot
                deny my belief that as a whole it needs to do more to promote
                women in leadership roles,” adds Laronde. “I will continue to do
                my part to change that narrative to empower women to reach and
                exceed their full potential.”
                <br></br>
                <br></br>
                More than 100 women have been recognized for making a
                transformational difference in their fields, while actively
                working to shape a more inclusive future especially in
                underrepresented areas.
                <br></br>
                <br></br>
                “It takes a powerful woman to lead like a legend in her field,
                organization, or community, and it takes a powerful woman to
                empower those around her to do the same,” said Sherri Stevens,
                CEO of WXN. “This year’s winners embody that power, giving us
                hope when we need it most, setting new bars and changing the
                status quo. They are legends celebrated not just for the things
                they do, but also how they touch the lives of others.”
                <br></br>
                <br></br>
                The Top 100 Awards span the private, public, and not-for-profit
                sectors, with the winners selected by WXNs Diversity Council of
                Canada.
                <br></br>
                <br></br>
                “What sets Lisa apart as a leader is her unwavering belief in
                inclusive leadership, one that fosters diversity of thought and
                encourages collaboration,” explains Jamie West, president of
                Peninsula Construction, an RSG International company.
                <br></br>
                <br></br>
                West sits on the board of the Canadian Association of Women in
                Construction, a group dedicated to breaking down barriers for
                women in construction, Laronde heads the board as its newly
                elected president.
                <br></br>
                <br></br>
                “Her [Laronde] unwavering support for the success of her team
                members, especially women in the industry, sets her apart as a
                beacon of inspiration and a true role model,” added West.
                Laronde says during her career she’s always led by example, been
                her unapologetic self and worked to be a transformational
                leader.
                <br></br>
                <br></br>
                “I want to be part of the solution, to blaze trails together,
                united with one voice. I don’t want women to follow in my
                footsteps, I want to see a path that’s free and clear and
                ultimately, I want to see new women leaders surpass me, bringing
                new and innovative ideas to the table.” Laronde’s
                accomplishments are being applauded by her team who believe she
                pushes them to never accept the status quo and always ask why.
                <br></br>
                <br></br>
                “Lisas want to push the envelope and willingness not to follow
                what has been done before is refreshing. I have found that
                having a woman for a leader changes the perspective and you are
                usually pushed in ways you wouldnt expect. Lisa exudes empathy,
                compassion, and passion and wants to continually do better.
                There is nothing greater than being challenged,” explains Elliot
                Steele, the Vice President, Technology at RSG International.
                <br></br>
                <br></br>
                Since 2012, the Canada’s Most Powerful Women: Top 100 Awards has
                recognized and celebrated a total of 1,628 extraordinary women
                including The Honourable Rona Ambrose, former leader of Canada’s
                Official Opposition in the House of Commons; Dr. Makaziwe
                Mandela, global activist, head of the House of Mandela and the
                daughter of Nelson Mandela; The Honourable Dr. Jean Augustine,
                the first African- Canadian woman elected to the House of
                Commons; Melissa Grelo, co-host of CTV’s The Social; Michele
                Romanow, tech entrepreneur and venture capitalist; and Susan
                Aglukark, award-winning musician.
              </p>
              <img
                className="card__image"
                src={NewsPhotoFour}
                alt="The CEO of RSG International, Lisa Laronde, standing in front Powell Construction equipement."
              />
            </div>
          </Link>

          {/* Individual card */}
          <Link
            to="/rsg-international-strikes-a-new-strategic-partnership"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">Oct 5th, 2023</span>
              <h3 className="card__title">
                RSG International Partners with Mark’s Commercial
              </h3>
              <p className="card__text">
                We’re very excited to announce a new strategic partnership
                between RSG International and{" "}
                <a
                  href="https://www.markscommercial.com/en-ca/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="article-page__link"
                >
                  Mark’s Commercial
                </a>{" "}
                focused on developing our safety-first work environment. Mark’s
                Commercial is Canada’s leading supplier of workwear, footwear,
                personal protective equipment (PPE), safety gear, and uniform
                programs, and understands our culture of safety and investment
                in innovative equipment for employees.
                <br></br>
                <br></br>
                This collaboration will help us to ensure the safety of our
                workforce, specifically with a tailored safety package for our
                employees. They will offer a tailored safety package for our
                workforce, including their male and true female fit 5-pt
                tearaway safety vest with the innovative VizLite DT technology
                that increases visibility of the wearer by up to 200 metres.
              </p>
              <img
                className="card__image"
                src={NewsPhotoThree}
                alt="A construction worker safely moving a beam onto a truck with the help of a crane."
              />
            </div>
          </Link>
          <Link
            to="/moving-road-safety-forward-through-innovation-with-elmo"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">Oct 3rd, 2023</span>
              <h3 className="card__title">
                Moving Road Safety Forward Through Innovation with Elmo
              </h3>
              <p className="article-page__text">
                We are pleased to announce our new partnership with{" "}
                <a
                  href="https://www.elmoremote.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="article-page__link"
                >
                  Elmo
                </a>
                ,the Estonian company behind the world´s first road-legal
                teledriving technology in operation. This new agreement
                underscores our dedication to innovation in road safety,
                constantly seeking new ways to keep road users safe everywhere.
                Elmo’s teledriving platform presents an excellent opportunity to
                test novel technology in the road safety sector, in particular
                when it comes to retrofitting our crash trucks, which can now be
                operated remotely.
                <br />
                <br />
                “We have tested self-driving vehicles, but teledriving is a much
                more effective solution for the road safety sector, both today
                and in the future. When Elmo picked us up at the airport in
                their teledriven car and drove us to the hotel, we immediately
                understood that this was the company we wanted to partner with,”
                stated RSG International’s Vice President of Technology, Elliot
                Steele. As leaders in the road safety sector, we are always
                looking for cutting-edge solutions to help drive our mission
                forward.
                <br />
                <br />
                Read our press release{" "}
                <a
                  href="https://www.einpresswire.com/article/659358989/global-road-safety-leader-rsg-international-partners-with-teledriving-innovator-elmo"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="article-page__link"
                >
                  here.
                </a>
              </p>
              <img
                className="card__image"
                src={NewsPhotoTwo}
                alt="A very large truck, holding a smaller truck, holding equipment, driving down a road."
              />
            </div>
          </Link>
          {/* Individual card */}
          <Link
            to="/observing-the-national-day-for-truth-and-reconciliation"
            className="card-link"
          >
            <div className="card">
              <span className="card__date">Sept 29th, 2023</span>
              <h3 className="card__title">
                Observing the National Day for Truth and Reconciliation
              </h3>
              <p className="card__text">
                Tomorrow we at RSG International join the rest of Canada in
                observing the National Day for Truth and Reconciliation.
                <br></br>
                <br></br>
                As global leaders in road safety infrastructure, we are
                committed to creating solutions that make the roadway safer for
                everyone, including Indigenous peoples. We recognize the tragic
                legacy of residential schools and the ongoing impacts of
                colonization on First Nations, Inuit, and Métis peoples. Also
                acknowledging that our work takes place on the traditional
                territories of many Indigenous nations across North America.
                <br></br>
                <br></br>
                On this day, we encourage our employees to wear orange to show
                solidarity with survivors and their descendants. We also invite
                you to explore the rich and diverse cultures, voices,
                experiences, and histories of Indigenous peoples through the
                resources available online and provided by the Government of
                Canada. Together, we can make a difference in advancing
                reconciliation and healing.
              </p>
              <img
                className="card__image"
                src={NewsPhotoOne}
                alt="Logo for EveryChildMatters. A painting of hands in a circle, with the words 'Every Child Matters' in the middle."
              />
            </div>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default News;
