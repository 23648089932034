import React, { useEffect } from "react";
import { BrowserRouter, Link, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./styles/App.scss";

import ReactGA from "react-ga";
import TitleRoute from "./components/TitleRoute/TitleRoute";
import CookieConsent from "react-cookie-consent";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

import NavBar from "./components/NavBar/NavBar";
import HomePage from "./pages/HomePage/HomePage";
import AboutPage from "./pages/AboutPage/AboutPage";
import MediaPage from "./pages/MediaPage/MediaPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import WhistleblowerPolicy from "./pages/WhistleblowerPolicy/WhistleBlowerPolicy";
import AccessibilityPage from "./pages/AccessibilityPage/AccessibilityPage";
import AccessibilityPlan from "./pages/AccessibilityPage/AccessibilityPlan/AccessibilityPlan";

import ArticleOne from "./pages/Articles/ArticleOne/ArticleOne";
import ArticleTwo from "./pages/Articles/ArticleTwo/ArticleTwo";
import ArticleThree from "./pages/Articles/ArticleThree/ArticleThree";
import ArticleFour from "./pages/Articles/ArticleFour/ArticleFour";
import ArticleFive from "./pages/Articles/ArticleFive/ArticleFive";
import ArticleSix from "./pages/Articles/ArticleSix/ArticleSix";
import ArticleSeven from "./pages/Articles/ArticleSeven/ArticleSeven";
import ArticleEight from "./pages/Articles/ArticleEight/ArticleEight";
import ArticleNine from "./pages/Articles/ArticleNine/ArticleNine";
import ArticleTen from "./pages/Articles/ArticleTen/ArticleTen";
import ArticleEleven from "./pages/Articles/ArticleEleven/ArticleEleven";
import ArticleTwelve from "./pages/Articles/ArticleTwelve/ArticleTwelve";
import ArticleThirteen from "./pages/Articles/ArticleThirteen/ArticleThirteen";
import ArticleFourteen from "./pages/Articles/ArticleFourteen/ArticleFourteen";
import ArticleFifteen from "./pages/Articles/ArticleFifteen/ArticleFifteen";
import ArticleSixteen from "./pages/Articles/ArticleSixteen/ArticleSixteen";
import ArticleSeventeen from "./pages/Articles/ArticleSeventeen/ArticleSeventeen";
import ArticleEighteen from "./pages/Articles/ArticleEighteen/ArticleEighteen";
import ArticleNineteen from "./pages/Articles/ArticleNineteen/ArticleNineteen";
import ArticleTwenty from "./pages/Articles/ArticleTwenty/ArticleTwenty";
import ArticleTwentyOne from "./pages/Articles/ArticleTwentyOne/ArticleTwentyOne";
import ArticleTwentyTwo from "./pages/Articles/ArticleTwentyTwo/ArticleTwentyTwo";
import ArticleTwentyThree from "./pages/Articles/ArticleTwentyThree/ArticleTwentyThree";
import ArticleTwentyFour from "./pages/Articles/ArticleTwentyFour/ArticleTwentyFour";
import ArticleTwentyFive from "./pages/Articles/ArticleTwentyFive/ArticleTwentyFive";
import ArticleTwentySix from "./pages/Articles/ArticleTwentySix/ArticleTwentySix";

function initializeAnalytics() {
  ReactGA.initialize("UA-XXXXXXXXX-X"); // replace 'UA-XXXXXXXXX-X' with your Tracking ID
  ReactGA.pageview(window.location.pathname + window.location.search);
}

function App() {
  React.useEffect(() => {
    initializeAnalytics();

    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <>
      <BrowserRouter>
        <NavBar />
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <TitleRoute title="Home - RSG International">
                <HomePage />
              </TitleRoute>
            }
          />
          <Route
            path="/about"
            element={
              <TitleRoute title="About Us - RSG International">
                <AboutPage />
              </TitleRoute>
            }
          />
          <Route
            path="/media"
            element={
              <TitleRoute title="Media - RSG International">
                <MediaPage />
              </TitleRoute>
            }
          />
          <Route
            path="/contact"
            element={
              <TitleRoute title="Contact - RSG International">
                <ContactPage />
              </TitleRoute>
            }
          />
          <Route
            path="/accessibility"
            element={
              <TitleRoute title="Accessibility - RSG International">
                <AccessibilityPage />
              </TitleRoute>
            }
          />
          <Route
            path="/accessibility-plan"
            element={
              <TitleRoute title="Accessibility - RSG International">
                <AccessibilityPlan />
              </TitleRoute>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <TitleRoute title="Privacy Policy - RSG International">
                <PrivacyPolicy />
              </TitleRoute>
            }
          />
          <Route
            path="/terms-of-use"
            element={
              <TitleRoute title="Terms of Use - RSG International">
                <TermsOfUse />
              </TitleRoute>
            }
          />
          {/* <Route
            path="/whistleblower"
            element={
              <TitleRoute title="Whistleblower Policy - RSG International">
                <WhistleblowerPolicy />
              </TitleRoute>
            }
          /> */}
          <Route
            path="/national-day-for-truth-and-reconciliation-2024"
            element={
              <TitleRoute
                title="National Day for Truth and Reconciliation - RSG International"
              >
                <ArticleTwentySix />
              </TitleRoute>
            }
          />
          <Route
            path="/rsg-international-celebrates-national-construction-appreciation-week"
            element={
              <TitleRoute
                title="RSG International Celebrates National Construction Appreciation Week - RSG International"
              >
                <ArticleTwentyFive />
              </TitleRoute>
            }
          />
          <Route
            path="/how-to-save-on-construction-costs-with-used-concrete-barriers"
            element={
              <TitleRoute
                title="How to Save on Construction Costs with Used Concrete Barriers - RSG International"
              >
                <ArticleTwentyFour />
              </TitleRoute>
            }
          />
          <Route
            path="/national-traffic-awareness-month"
            element={
              <TitleRoute title="National Traffic Awareness Month - RSG International">
                <ArticleTwentyThree />
              </TitleRoute>
            }
          />
          <Route
            path="/project-highlight-bk-enterprises-installing-concrete-barrier-in-bucks-county-pennsylvania"
            element={
              <TitleRoute
                title=" Project Highlight: B&K Enterprises Installing Concrete Barrier in
          Bucks County, Pennsylvania - RSG International"
              >
                <ArticleTwentyTwo />
              </TitleRoute>
            }
          />
          <Route
            path="/rsg-international-is-acquired-by-ramudden-global-bolstering-its-global-ambitions"
            element={
              <TitleRoute title="RSG International is Acquired by Ramudden Global, Bolstering Its Global Ambitions - RSG International">
                <ArticleTwentyOne />
              </TitleRoute>
            }
          />
          <Route
            path="/employee-spotlight-sharon-trac-at-powell-contracting"
            element={
              <TitleRoute title="Employee Spotlight: Sharon Trac at Powell Contracting - RSG International">
                <ArticleTwenty />
              </TitleRoute>
            }
          />
          <Route
            path="/rsg-international-named-one-of-canadas-best-places-to-work"
            element={
              <TitleRoute title="RSG International Named One of Canada’s Best Places to Work - RSG International">
                <ArticleNineteen />
              </TitleRoute>
            }
          />
          <Route
            path="/national-indigenous-history-month-celebrating-indigenous-cultures"
            element={
              <TitleRoute title="National Indigenous History Month: Celebrating Indigenous Cultures - RSG International">
                <ArticleEighteen />
              </TitleRoute>
            }
          />
          <Route
            path="/celebrating-pride-month-at-rsg-international"
            element={
              <TitleRoute title="Celebrating Pride Month at RSG International - RSG International">
                <ArticleSeventeen />
              </TitleRoute>
            }
          />
          <Route
            path="/rsg-international-lights-the-path-for-pink-titans-robotics-in-2025"
            element={
              <TitleRoute title="RSG International Lights the Path for Pink Titans Robotics in 2025 - RSG International">
                <ArticleSixteen />
              </TitleRoute>
            }
          />
          <Route
            path="/rsg-international-hr-team-clinches-the-innovative-hr-teams-award"
            element={
              <TitleRoute title="RSG International's HR Team Clinches the Innovative HR Teams Award - RSG International">
                <ArticleFifteen />
              </TitleRoute>
            }
          />
          <Route
            path="/chantal-seguin-elected-president-of-the-simcoe-county-heavy-construction-association"
            element={
              <TitleRoute title="Chantal Séguin Elected President of the Simcoe County Heavy Construction Association - RSG International">
                <ArticleFourteen />
              </TitleRoute>
            }
          />
          <Route
            path="/recognizing-mental-health-awareness-month"
            element={
              <TitleRoute title="Recognizing Mental Health Awareness Month - RSG International">
                <ArticleThirteen />
              </TitleRoute>
            }
          />
          <Route
            path="/lisa-laronde-president-of-rsg-international-has-been-named-one-of-the-most-influential-people-of-2024-by-sitenews"
            element={
              <TitleRoute
                title="Lisa Laronde, President of RSG International, has been named one of
              the Most Influential People of 2024 by SiteNews - RSG International"
              >
                <ArticleTwelve />
              </TitleRoute>
            }
          />
          <Route
            path="/canadas-first-teledriven-crash-truck-debuted-at-intertraffic"
            element={
              <TitleRoute title="Canada's First Teledriven Crash Truck Debuted at Intertraffic - RSG International">
                <ArticleEleven />
              </TitleRoute>
            }
          />
          <Route
            path="/president-of-rsg-international-launches-podcast-on-international-womens-day"
            element={
              <TitleRoute title="Celebrating Women in Construction Week - RSG International">
                <ArticleTen />
              </TitleRoute>
            }
          />
          <Route
            path="/celebrating-women-in-construction-week"
            element={
              <TitleRoute title="Celebrating Women in Construction Week - RSG International">
                <ArticleNine />
              </TitleRoute>
            }
          />
          <Route
            path="/jason-spencer-joins-rsg-internationals-executive-team"
            element={
              <TitleRoute title="Jason Spencer Joins RSG International’s Executive Team - RSG International">
                <ArticleEight />
              </TitleRoute>
            }
          />
          <Route
            path="/lisa-laronde-is-appointed-to-the-board-of-orba"
            element={
              <TitleRoute title="Lisa Laronde Is Appointed to the Board of ORBA - RSG International">
                <ArticleSeven />
              </TitleRoute>
            }
          />
          <Route
            path="/mark-ayton-from-safe-roads-research-development-becomes-the-first-canadian-to-win-a-lifetime-award-from-the-transportation-research-board"
            element={
              <TitleRoute title="Mark Ayton From Safe Roads Research & Development Becomes the First Canadian to Win a Lifetime Award From the Transportation Research Board - RSG International">
                <ArticleSix />
              </TitleRoute>
            }
          />
          <Route
            path="/rsg-international-looks-to-team-up-employees-with-ai-partner"
            element={
              <TitleRoute title="RSG International Looks To Team Up Employees With AI Partner - RSG International">
                <ArticleFive />
              </TitleRoute>
            }
          />
          <Route
            path="/lisa-laronde-wxn-2023-canadas-most-powerful-women"
            element={
              <TitleRoute title="Lisa Laronde: WXN 2023 Canada's Most Powerful Women - RSG International">
                <ArticleFour />
              </TitleRoute>
            }
          />
          <Route
            path="/rsg-international-strikes-a-new-strategic-partnership"
            element={
              <TitleRoute title="RSG International Strikes a New Strategic Partnership - RSG International">
                <ArticleThree />
              </TitleRoute>
            }
          />
          <Route
            path="/moving-road-safety-forward-through-innovation-with-elmo"
            element={
              <TitleRoute title="Moving Road Safety Forward Through Innovation with Elmo - RSG International">
                <ArticleTwo />
              </TitleRoute>
            }
          />
          <Route
            path="/observing-the-national-day-for-truth-and-reconciliation"
            element={
              <TitleRoute title="Observing the National Day for Truth and Reconciliation - RSG International">
                <ArticleOne />
              </TitleRoute>
            }
          />
        </Routes>

        <CookieConsent
          enableDeclineButton
          flipButtons
          location="bottom"
          buttonText="Accept Cookies"
          declineButtonText="Decline Cookies"
          cookieName="rsgcookies"
          style={{
            background: "#FFFFFF",
            color: "#000000",
            fontSize: "15px",
            padding: "20px", // Adjust this value as needed
            paddingRight: "140px",
          }}
          buttonStyle={{
            background: "#00007C",
            color: "#FFFFFF",
            fontSize: "10px",
            padding: "10px",
          }}
          declineButtonStyle={{
            background: "#00007C",
            color: "#FFFFFF",
            fontSize: "10px",
            padding: "10px",
          }}
          expires={150}
        >
          We use cookies to enhance your browsing experience, analyze our
          traffic, and for marketing purposes. By continuing to use our site,
          you consent to our use of cookies.
          <span style={{ fontSize: "15px" }}>
            {" "}
            For more information on how we use your data, and to manage your
            cookie preferences, view our{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>.
          </span>
        </CookieConsent>
      </BrowserRouter>
    </>
  );
}

export default App;
